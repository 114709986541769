@use "sass:math";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

$mobile: 768px;
$animationDuration: 0.5s;
$gray: #dedede;
$gray2: #b4b4b4;
$gray3: #474747;
$extra-gray: #a5a5a5;
$small-font: 0.8vw;
$med1-font: 1vw;
$med2-font: 1.2vw;
$med-font: 2vw;
$med-large-font: 3vw;
$large-font: 3.5vw;
$extra-large-font: 15vw;
$aspect-ratio: math.div(16, 9); //Other resolution: 1100/500
$border-radius: 0.5vw;

@font-face {
  font-family: Font;
  src: url("../res/fonts/Garet-Book.ttf");
}

@font-face {
  font-family: FontBold;
  src: url("../res/fonts/Garet-Heavy.ttf");
}

@font-face {
  font-family: Manrope;
  font-weight: 300;
  src: url("../res/fonts/Manrope-VariableFont_wght.ttf");
}
* {
  font-family: Manrope;
  //user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.App {
  min-height: 100vh;
}

body {
  background-image: url("../res/images/bgwhite.jpg");
  object-fit: cover;
  background-size: 100% 50%;
}

.container {
  margin: auto;
}

.projects-title {
  opacity: 0;
  transform: translateY(150px);
  font-size: $extra-large-font;
  color: $gray;
  text-align: center;
  margin: 0;
  transition: all $animationDuration ease;
}

.card-container {
  position: relative;

  width: 45vw;
  height: calc(45vw / 16 * 9);
  // filter: brightness(0.8);
  aspect-ratio: $aspect-ratio;
  border: none;
  opacity: 0;
  border-radius: $border-radius;
  transition: all $animationDuration ease;
  transform: translateY(50px);
  box-shadow: 0.1vw 0.1vw 0.3vw 0 rgba(0, 0, 0, 1);

  @media screen and (max-width: $mobile) {
    width: 90vw;
    height: calc(90vw / 16 * 9);
  }
}

.services-card-container {
  position: relative;
  width: 45vw;
  height: calc(45vw / 16 * 9);

  // filter: brightness(0.8);
  opacity: 1;
  aspect-ratio: $aspect-ratio;
  border: none;
  border-radius: $border-radius;
  transition: all $animationDuration ease;
  // transform: translateY(50px);
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: $mobile) {
    width: 90vw;
    height: calc(90vw / 16 * 9);
  }
}

.services-card-container:hover {
  opacity: 1;
  transform: scale(1.02);
  /*filter: brightness(1);*/
  .services-card-content {
    opacity: 1;
  }
}

.services-card-image {
  box-sizing: border-box;
  display: block;
  object-fit: cover;
  border-radius: $border-radius;
  border: none;
  width: 100%;
  height: 100%;
  aspect-ratio: $aspect-ratio;
}

.services-card-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: $med-font;
  color: rgb(71, 71, 71);
  transition: all $animationDuration ease;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  @media screen and (max-width: $mobile) {
    font-size: ($med-font * 2);
  }
}

.services-card-bottom-bar {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  height: 20%;
  width: 100%;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.1vw);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: rgba(255, 255, 255, 0.8);
}
.services-back-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1vw;
  box-sizing: border-box;
  border-radius: $border-radius;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(0.1vw);
}
.services-card-back {
}

.services-card-title {
  font-weight: bold;
}

.services-card-text {
  text-align: center;
  font-size: $med1-font;
  @media screen and (max-width: $mobile) {
    font-size: ($med1-font * 2);
  }
}
.services-card-button {
  text-align: center;
  font-size: $med-font;
  color: $gray3;
  background-color: transparent;
  border-radius: 0.3vw;
  padding: 0.5vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  /* cursor: pointer;*/
  font-weight: bold;
  transition: all $animationDuration ease;
  @media screen and (max-width: $mobile) {
    font-size: ($med-font * 2);
  }
  /* &:hover {
        transform: scale(1.1);
    }*/
}
.active {
  transform: translateY(0);
  opacity: 0.8;
}

.projects-active {
  transform: translateY(0);
  opacity: 0.3;
  transition-delay: 0s;
}

.services-title {
  margin: 0;
  text-align: center;
  font-size: $med-large-font;
  padding-top: 100px;
  color: #5f5f5f;
  font-weight: 200;
  @media screen and (max-width: $mobile) {
    font-size: calc($med-large-font * 2);
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2vw;
  justify-content: center;
  align-items: center;
  row-gap: 2vw;
  column-gap: 2vw;
  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2vw;
  justify-content: center;
  align-items: center;
  row-gap: 2vw;
  column-gap: 2vw;
  max-width: 70vw;
  height: min-content;
  flex-direction: column;
  transition: all 0.5s ease;
  @media screen and (max-width: $mobile) {
    max-width: unset;
  }
}
canvas {
  background-image: url("../res/images/bg.jpg");
  object-fit: cover;
  background-size: 100% 100%;
}

.card-image {
  display: block;
  border-radius: $border-radius;
  object-fit: cover;
  border: none;
  height: 100%;
  width: 100%;
}

.card-container:hover {
  opacity: 1;
  transform: scale(1.02);
  /*filter: brightness(1);*/
  .card-content {
    opacity: 1;
  }
}

.card-bottom-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 20%;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 3%;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.1vw);
}

.card-bottom-bar-with-button {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  height: 20%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 3%;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.1vw);
}
.gamecard-apple,
.gamecard-google {
  position: relative;
  height: 100%;
  padding: 3%;
  flex: 1;
  margin: 2%;
  border-radius: 0.7vw;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  border: none;
  box-shadow: 0.35vw 0.35vw 0.7vw 0px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}

.gamecard-apple {
  background: linear-gradient(to right, rgba(0, 255, 255, 0.5) 0, rgba(0, 255, 128, 0.5) 100%);
}

.gamecard-google {
  background: linear-gradient(to right, rgba(0, 255, 128, 0.5) 0, rgba(0, 255, 0, 0.5) 100%);
}

.gamecard-google:hover,
.gamecard-apple:hover {
  transform: scale(1.04);
}

.button-logo {
  position: absolute;
  width: 15%;
  left: 15%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.button-text {
  position: absolute;
  width: 65%;
  left: 30%;
  top: 50%;
  font-size: $small-font;
  transform: translateY(-50%);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  letter-spacing: 0.06vw;
  @media screen and (max-width: $mobile) {
    font-size: ($small-font * 2);
  }
}

.gamecard-button-container {
  display: flex;
  position: absolute;
  height: 15%;
  top: 63%;
  left: 35%;
  width: 60%;
  transform: translate(-50%, -50%);
}
.gamecard-logo {
  position: absolute;
  top: 30%;
  left: 35%;
  width: 70%;
  transform: translate(-50%, -50%);
}

.gamecard-video {
  position: absolute;
  top: 5%;
  right: 5%;
  height: 70%;
  border-radius: 0.5vw;
  z-index: 20;
  box-shadow: 0.1vw 0.1vw 0.3vw 0.05vw rgba(0, 0, 0, 1);
  transition: all $animationDuration ease;
}

.gamecard-video:hover {
  transform: scale(1.2);
}

.card-content {
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all $animationDuration ease;
}

.services-card-content {
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all $animationDuration ease;
}
.services-card-bottom-title {
  font-size: $med-font;
  color: $gray3;
  @media screen and (max-width: $mobile) {
    font-size: ($med-font * 2);
  }
  /*  background: -webkit-linear-gradient(-75deg, #00ffff 30%, #00ff00 55%, #ffff00 75%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px transparent;*/
}

#services {
  height: min-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: $mobile) {
    height: 115vh;
  }
}

.card-bottom-title {
  font-size: $med-font;
  color: $gray;
  @media screen and (max-width: $mobile) {
    font-size: ($med-font * 2);
  }
  /*  background: -webkit-linear-gradient(-75deg, #00ffff 30%, #00ff00 55%, #ffff00 75%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px transparent;*/
}

.card-bottom-categories {
  text-transform: uppercase;
  font-size: $small-font;
  color: $extra-gray;
  @media screen and (max-width: $mobile) {
    font-size: ($small-font * 2);
  }
}

#nodes {
  position: fixed;
  z-index: -99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all $animationDuration ease;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  z-index: 500;
  padding: 10px;
  transition: all $animationDuration ease;
  text-align: right;
  backdrop-filter: blur(3px);
  position: fixed;
  font-size: 24px;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}

.header-element {
  color: black;
  cursor: pointer;
  display: inline-block;
  padding-left: 6.5px;
  color: $gray;
  text-transform: uppercase;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  .header-element {
    color: $gray;
  }
}
/*filter.sticky + .container {
    padding-top: 4vw;
}
*/
.projectcard-video {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.5vw;
  transition: all $animationDuration ease;
}

.rl-video1 {
  position: absolute;
  height: 35%;
  left: 2.5%;
  top: 2.5%;
  z-index: 1;
  aspect-ratio: 1;
  opacity: 0.8;
  transition: all $animationDuration ease;
  &:hover {
    opacity: 0.99;
    z-index: 2;
    transform: scale(1.5);
  }
}

.rl-video2 {
  position: absolute;
  top: 42.5%;
  height: 35%;
  left: 2.5%;
  z-index: 1;
  aspect-ratio: 1;
  opacity: 0.8;
  transition: all $animationDuration ease;
  &:hover {
    z-index: 2;
    opacity: 0.99;
    transform: scale(1.5);
  }
}

.rl-video3 {
  position: absolute;
  height: 75%;
  right: 2.5%;
  top: 2.5%;
  z-index: 1;
  aspect-ratio: 992/576;
  opacity: 0.8;
  transition: all $animationDuration ease;
  &:hover {
    z-index: 2;
    opacity: 0.99;
    transform: scale(1.1);
  }
}

.rl-bg-image {
  filter: hue-rotate(180deg);
}

.header-item {
  font-size: 1.2rem;
  margin-right: 1.5rem;
  text-decoration: none;
  transition: all 0.5s ease;
  font-weight: bold;
  color: $gray2;
  text-transform: uppercase;
}

.header-separator {
  font-size: 1.2rem;
  margin-right: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  color: $gray2;
  text-transform: uppercase;
}

.header-item:hover {
  color: rgb(255, 255, 255);
}

.above-title,
.below-title {
  text-align: center;
  transition: all 0.5s ease;
  margin: 0;
  font-weight: normal;
  color: $gray2;
  opacity: 0;
}

.above-title {
  font-size: $med2-font;
  transform: translateY(-50px);
  transition-delay: 0s !important;
  @media screen and (max-width: $mobile) {
    font-size: ($med2-font * 2);
  }
}

.below-title {
  opacity: 0;
  color: rgb(255, 255, 255);
  transform: translateY(150px);
  font-size: $med-font;
  @media screen and (max-width: $mobile) {
    font-size: ($med-font * 2);
  }
}

.subtitle-active {
  transform: translateY(0);
  opacity: 0.5;
  transition-delay: 0.3s;
  color: rgb(255, 255, 255);
}

.title-container {
  padding-bottom: 150px;
  padding-top: 150px;
}

.separator {
  transform: translateY(-100px);
  width: 60%;
  opacity: 0.5;
  border-color: $gray;
  transition: all 0.5s ease;
}

.separator2 {
  width: 60%;
  opacity: 0.5;
  border-color: $gray;
  transition: all 0.5s ease;
}

.separator-active {
  transform: translateY(-50px);
}

.card-bottom-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1vw;
  cursor: pointer;
  padding: 0.5vw;
  padding-left: 1vw;
  padding-right: 1vw;
  font-weight: bold;
  font-size: $small-font;
  color: $gray;
  border-radius: 0.5vw;
  border: 0.1vw solid $gray;
  background-color: rgba(255, 255, 255, 0.3);
  @media screen and (max-width: $mobile) {
    font-size: ($small-font * 2);
  }
  transition: all 0.5s ease;
}

.card-bottom-button:hover {
  transform: scale(1.2);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 35px;
  padding: 5px;
}

.logos {
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  column-gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
}

.email {
  color: black;
  font-weight: bold;
  font-size: 22px;
}
